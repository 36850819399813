import React from "react";
import ReactDOM from "react-dom";
import "./bundle.scss";
import App from "./components/section";

console.log("We made it! like me", {App});

export {
    React,
    ReactDOM,
    App
}