import React, {useEffect} from "react";
import "./index.scss";

export default ({message, subtitle}: {message?: string, subtitle?: string}) => {
    const messArr = message && message.split("") || [];
    
    useEffect(() => {
        let arr = [];
        if (messArr) {
            for (var i = 0; i < messArr.length; i++) {
                arr.push(i)
            }
        }

        let inc = 0;
        arr.sort(() => 0.5 - Math.random())
        .forEach((key: number) => {
            setTimeout(() => {
                // @ts-ignore
                document.querySelector(`.blur_${key}`).classList.remove("blur");
            }, inc * 150);
            inc++;
        })
    }, [])
    
    return (
        <section>

        <div className="message zoom">
            {messArr.map((char, i) => <span className={`blur blur_${i}`}>{char}</span>)}
        </div>
            <div dangerouslySetInnerHTML={{__html: `${subtitle}`}}/>
        </section>
    )
}